const states = {
    PAGE:{
        INTRO: 'INTRO',
        INPUT: 'INPUT',
        RESULT: 'RESULT',
        REVEAL: 'REVEAL'

    } 
};

export default states