import React, { Fragment, useState } from "react";
import ButtonsBar from "./ButtonsBar";
import ENUM from "../ENUM";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

function Input({ setPage, query }) {
  const [studentId, setStudentId] = useState("");
  const [citizenId, setCitizenId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const onlyDigits = (text) => text.replace(/\D+/g, "");
  const formattedCid = (cid) => {
    let cid_str = onlyDigits(cid);
    cid_str = cid_str.substr(0, 13);
    let formattedCid = "";
    for (let d = 0; d < cid_str.length; d++) {
      formattedCid += cid_str[d];
      if ([0, 4, 9, 11].indexOf(d) >= 0) {
        formattedCid += "-";
      }
    }
    return formattedCid;
  };
  const handleFormChange = (event) => {
    switch (event.target.name) {
      case "studentId":
        const output = onlyDigits(event.target.value).substr(0, 5);
        console.log("INOUT", event.target.value, output);
        setStudentId(onlyDigits(event.target.value).substr(0, 5));
        break;
      case "citizenId":
        //remove -
        let cid_str = event.target.value;
        if (cid_str + "-" === citizenId) {
          //delete key
          setCitizenId(cid_str.substr(0, cid_str.length - 1));
        } else {
          setCitizenId(formattedCid(cid_str));
        }

        break;
      default:
    }
  };
  const handleReset = () => {
    setPage(ENUM.PAGE.INTRO);
  };
  const handleSubmit = () => {
    setLoading(true);
    query(studentId, onlyDigits(citizenId), (res) => {
      setLoading(false);
      if (res === "success") {
        setShowError(false);
        setPage(ENUM.PAGE.RESULT);
      } else {
        setShowError(true);
      }
    });
  };

  return (
    <Fragment>
      <h5 className="text-primary">กรอกข้อมูลส่วนตัว</h5>
      <Form>
        <Form.Group controlId="studentId">
          <Form.Label>รหัสนักเรียน</Form.Label>
          <Form.Control
            type="tel"
            placeholder="ใส่รหัสนักเรียน 5 หลัก"
            autoComplete="off"
            name="studentId"
            onChange={handleFormChange}
            value={studentId}
            disabled={loading}
          />
        </Form.Group>
        <Form.Group controlId="citizenId">
          <Form.Label>เลขบัตรประชาชน</Form.Label>
          <Form.Control
            type="tel"
            placeholder="ใส่เลขบัตรประชาชน 13 หลัก"
            autoComplete="off"
            name="citizenId"
            onChange={handleFormChange}
            value={citizenId}
            disabled={loading}
          />
        </Form.Group>
      </Form>
      <Alert variant="error" hidden={!showError}>
        <span className="font-weight-bold mr-2">
          <span role="img" aria-label="warning">
            ⚠️
          </span>
          ไม่พบข้อมูล
        </span>
        <div>กรุณาตรวจสอบความถูกต้องของข้อมูลอีกครั้ง</div>
        <div>
          หากตรวจสอบแล้วยังคงไม่พบข้อมูล ติดต่อ Line:
          <a
            href="http://line.me/ti/p/~@khemasiri"
            target="_blank"
            rel="noopener noreferrer"
          >
            @khemasiri
          </a>
        </div>
      </Alert>
      <ButtonsBar
        loading={loading}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
      />
    </Fragment>
  );
}

export default Input;
