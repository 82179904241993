import React, { useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import ENUM from "./ENUM";
import Intro from "./Components/Intro";
import Input from "./Components/Input";
import Result from "./Components/Result";
import Reveal from "./Components/Reveal";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import firebase from "./firebase.js";

function App() {
  const [page, setPage] = useState(ENUM.PAGE.INTRO);
  const [student, setStudent] = useState({});
  const queryStudent = (sid, cid, cb) => {
    const query = firebase.app().functions("asia-east2").httpsCallable("query");
    query({ sid: sid, cid: cid })
      .then((result) => {
        console.log("result", result);
        if (result.data.name) {
          setStudent(result.data);
          cb("success");
        } else {
          setStudent({});
          cb("wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        cb("error", error);
      });
  };
  return (
    <Container className="App">
      <Row className="mt-5 mb-3">
        <Col className="text-center">
          <h2>รับรหัสเรียนออนไลน์</h2>
          <h3>โรงเรียนเขมะสิริอนุสสรณ์</h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          sm={10}
          md={8}
          lg={6}
          className="d-flex flex-column justify-content-between"
          // style={{ height: "300px" }}
        >
          {page === ENUM.PAGE.INTRO ? <Intro setPage={setPage} /> : null}
          {page === ENUM.PAGE.INPUT ? (
            <Input setPage={setPage} query={queryStudent} />
          ) : null}
          {page === ENUM.PAGE.RESULT ? (
            <Result setPage={setPage} student={student} />
          ) : null}
          {page === ENUM.PAGE.REVEAL ? (
            <Reveal setPage={setPage} student={student} />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
