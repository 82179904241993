import React, { Fragment } from "react";
import ButtonsBar from "./ButtonsBar.js";
import ENUM from "../ENUM";

import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function Result({ setPage, student }) {
  const handleSubmit = () => {
    setPage(ENUM.PAGE.REVEAL);
  };
  return (
    <Fragment>
      <h5 className="text-primary">ยืนยัน</h5>
      <div className="mt-2">
        <ListGroup>
          <ListGroup.Item>
            <Row>
              <Col xs={4} sm={4} className="font-weight-bold text-right">
                ชื่อ
              </Col>
              <Col>
                {student.name} {student.surname}
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col xs={4} sm={4} className="font-weight-bold text-right">
                เลขประจำตัว
              </Col>
              <Col>{student.sid}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col xs={4} sm={4} className="font-weight-bold text-right">
                ชั้น
              </Col>
              <Col>
                {student.year}/{student.room}
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>

        <p className="mt-4 mb-4 text-center">
          หากต้องการแก้ไขข้อมูล ติดต่อ Line:{" "}
          <a
            href="http://line.me/ti/p/~@khemasiri"
            target="_blank"
            rel="noopener noreferrer"
          >
            @khemasiri
          </a>
        </p>
      </div>

      <ButtonsBar
        submitText="ต่อไป"
        resetable={true}
        handleSubmit={handleSubmit}
        handleReset={() => {
          setPage(ENUM.PAGE.INTRO);
        }}
      />
    </Fragment>
  );
}

export default Result;
