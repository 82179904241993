import React, { Fragment } from "react";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

function ButtonsBar({
  resetable = true,
  submitable = true,
  loading = false,
  submitEnabled = true,
  submitText = "ต่อไป",
  handleReset,
  handleSubmit,
}) {
  const renderResetButton = () => {
    if (resetable) {
      return (
        <Button variant="secondary" type="reset" onClick={handleReset}>
          เริ่มใหม่
        </Button>
      );
    } else return null;
  };
  const renderSpinner = () => {
    return (
      <Fragment>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />

        <span className="sr-only">Loading...</span>
      </Fragment>
    );
  };

  return (
    <Row className="justify-content-between">
      <Col sm="auto" xs="auto">
        {renderResetButton()}
      </Col>
      <Col sm={4} xs={6}>
        <Button
          className="w-100"
          variant="primary"
          type="button"
          onClick={handleSubmit}
          hidden={!submitable}
          disabled={loading}
        >
          {loading ? renderSpinner() : submitText}
        </Button>
      </Col>
    </Row>
  );
}

export default ButtonsBar;
