import React, { Fragment } from "react";
import ButtonsBar from "./ButtonsBar.js";
import ENUM from "../ENUM";

import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
function Reveal({ setPage, student }) {
  const formatEmail = (email) => {
    const a = email.split("@");
    return a[0] + "@" + "&#8203;" + a[1];
  };
  return (
    <Fragment>
      <h5 className="text-primary">รับรหัส</h5>
      <div className="mt-2">
        <ListGroup>
          <ListGroup.Item>
            <Row>
              <Col xs={4} sm={4} className="font-weight-bold text-right">
                ชื่อ
              </Col>
              <Col>
                {student.name} {student.surname}
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col
                xs={4}
                sm={4}
                className="font-weight-bold text-right text-no-wrap"
              >
                เลขประจำตัว
              </Col>
              <Col>{student.sid}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col xs={4} sm={4} className="font-weight-bold text-right">
                ชื่อผู้ใช้
              </Col>
              <Col className="breakword font-weight-bold">{student.email}</Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col xs={4} sm={4} className="font-weight-bold text-right">
                รหัสผ่านเข้าใช้งานครั้งแรก
              </Col>
              <Col>{student.password}</Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
        <Alert variant="warning" className="mt-1">
          <span className="font-weight-bold mr-2">
            <span role="img" aria-label="phone">
              📲
            </span>
            <u>ถ่ายภาพหน้าจอนี้ไว้</u>
          </span>
          หรือจดรหัสใส่กระดาษ เพื่อป้องกันการลืมรหัสผ่าน
        </Alert>
        <Alert variant="info">
          เข้าห้องเรียนออนไลน์ได้ที่{" "}
          <a
            href="https://classroom.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            classroom.google.com
          </a>
        </Alert>
      </div>

      <ButtonsBar
        submitable={false}
        resetable={true}
        handleReset={() => {
          setPage(ENUM.PAGE.INTRO);
        }}
      />
    </Fragment>
  );
}

export default Reveal;
