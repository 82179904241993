import React, { Fragment } from "react";
import ButtonsBar from "./ButtonsBar.js";
import ENUM from "../ENUM";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

function Intro({ setPage }) {
  const handleSubmit = () => {
    setPage(ENUM.PAGE.INPUT);
  };
  return (
    <Fragment>
      <div className="">
        <h5 className="text-primary">ดาวน์โหลด</h5>
        <div>
          <Button
            href="/pdfs/คู่มือการเรียนออนไลน์.pdf"
            target="_blank"
            variant="outline-dark"
            className="mr-3"
          >
            คู่มือการเรียนออนไลน์
          </Button>
          {/* <Button
            href="/pdfs/Timetable1-64_p1-m6.pdf"
            target="_blank"
            variant="outline-dark"
            className="mr-3"
          >
            ตารางเรียนออนไลน์ ป.1 - ม.6
          </Button> */}
          {/* <div className="mt-2">
            <Button
              href="https://www.khemasiri.ac.th/pdf/2563/Online_KMS_P1-6.pdf"
              target="_blank"
              variant="outline-dark"
              className="mr-3"
            >
              ตารางเรียนออนไลน์ ประถมฯ
            </Button>
            <Button
              href="https://www.khemasiri.ac.th/pdf/2563/Online_KMS_M1-6.pdf"
              target="_blank"
              variant="outline-dark"
              className="mr-3"
            >
              ตารางเรียนออนไลน์ มัธยมฯ
            </Button>
          </div> */}
        </div>
      </div>
      <div className="mt-5">
        <h5 className="text-primary">รับรหัสเรียนออนไลน์</h5>
        <ol>
          <li>
            ให้นักเรียนอ่านวิธีการเข้าระบบใน{" "}
            <strong>คู่มือเรียนออนไลน์ </strong>
            อย่างละเอียด
          </li>
          <li>
            กรอกรหัสนักเรียน และ เลขประจำตัวประชาชนของตนเอง
            เพื่อรับรหัสเข้าบัญชี Google ของโรงเรียน
          </li>
          <li>ใช้บัญชีนี้ในการเรียนออนไลน์ผ่าน Google Classroom</li>
        </ol>
      </div>

      <ButtonsBar
        // className="align-self-end"
        resetable={false}
        handleSubmit={handleSubmit}
      />
    </Fragment>
  );
}

export default Intro;
