import firebase from "firebase/app";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBNyuDlgdLLLwYgnA15RX4qB2nC--wawNU",
  authDomain: "student-email-rollout.firebaseapp.com",
  databaseURL: "https://student-email-rollout.firebaseio.com",
  projectId: "student-email-rollout",
  storageBucket: "student-email-rollout.appspot.com",
  messagingSenderId: "1015076388854",
  appId: "1:1015076388854:web:5573581e66397a9bbe51d7",
  measurementId: "G-HLHP35VMYM",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
